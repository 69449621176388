@font-face {
  font-family: "Matter-Regular";
  src: url("./assets/fonts/Matter/Matter-Regular.woff2") format("woff2"),
    url("./assets/fonts/Matter/Matter-Regular.woff") format("woff"),
    url("./assets/fonts/Matter/Matter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Matter-SemiBold";
  src: url("./assets/fonts/Matter/Matter-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Matter/Matter-SemiBold.woff") format("woff"),
    url("./assets/fonts/Matter/Matter-SemiBold.ttf") format("truetype");
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-pre {
  padding: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
